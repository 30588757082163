import { memberApi } from "@/api/member.js";
import { carApi } from "@/api/car.js";
import dayjs from "dayjs";
export default {
  components: {},
  data: () => ({
    addNewDialog: false,
    from: "",
    to: "",
    departureTime: "",
    carPhone: "",
    licensePlates: "",
    driverId: "",
    listDriver: [],
    listCar: [],
    deleteItem: null,
    openDialogDelete: false,
  }),
  created() {
    this.getListDriver();
    this.getListCar();
  },
  methods: {
    async getListDriver() {
      let body = {
        role: "driver",
      };
      await memberApi.getListMember(body).then((res) => {
        this.listDriver = res;
      });
    },
    async createNewCar() {
      let body = {
        from: this.from,
        to: this.to,
        departureTime: this.departureTime,
        carPhone: this.carPhone,
        licensePlates: this.licensePlates,
        driverId: this.driverId,
      };
      await carApi.createNewCar(body).then((res) => {
        if (res.id) {
          this.$vs.notification({
            color: "success",
            position: "top-right",
            duration: "2000",
            title: "Tạo xe thành công !",
          });
          this.addNewDialog = false;
          this.getListCar();
        }
      });
    },
    async getListCar() {
      const loading = this.$vs.loading({
        target: this.$refs.createBooking,
      });
      await carApi.getListCar().then((res) => {
        this.listCar = res;
      });
      loading.close();
    },
    async confirmDeleteCar() {
      await carApi.deleteCar({ id: this.deleteItem.ID }).then(() => {
        this.getListCar();
        this.openDialogDelete = false;
      });
    },
    _dayjs: dayjs,
  },
};
